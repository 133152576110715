/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect  } from "react";
import Modal from 'react-modal';
import axios from "axios";
import { Link, useParams, useNavigate } from 'react-router-dom';

import config from '../../config';
import default01 from './images/default01.png';

import ErrorMessage from '../../components/ErrorMessage';
import SearchableStockGallery from '../../components/SearchableStockGallery';

import Loading from '../../components/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const initialEventState = {
  event_name: "",
  start_date: "",
  end_date: "",
  minimum_age: "",
  maximum_age: "",
  registration_required: false,
  specific_location: "",
  description: "",
  photo_filename: null,
  address: {
    city: "",
    postal_code: "",
    state: "",
    street: "",
    street2: ""
  }
};

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

const eventFields = [
  { label: 'Event Name', key: 'event_name', required: true, type: 'text' },
  { label: 'Location', key: 'specific_location', required: true },
  { label: 'Start Date', key: 'start_date', required: true, type: 'calendar' },
  { label: 'End Date', key: 'end_date', required: true, type: 'calendar' },
  { label: 'Description', key: 'description', required: true },
  { label: 'Min Age', key: 'minimum_age', required: true, type: 'dropdown', options: ['No Min', ...Array(55).keys()]  },
  { label: 'Max Age', key: 'maximum_age', required: true, type: 'dropdown', options: ['No Max', ...Array(55).keys(), '55+'].filter(item => item !== 0)  },
  // ... other fields you may have
];

const eventAddressFields = [
  { label: 'Address', key: 'street', required: false },
  { label: 'Extended Address', key: 'street2', required: false },
  { label: 'City', key: 'city', required: false },
  { label: 'State', key: 'state', required: false, type: 'dropdown', options: states },
  { label: 'Zip Code', key: 'postal_code', required: true },
  // ... other fields
];


const icons = {
  // Add other icons here as needed
};

const validatateEvent = (event) => {
  const { event_name, start_date, end_date, minimum_age, maximum_age } = event;
  const { street, street2, city, state, postal_code } = event.address;

  let errors = {};

  // Validation for event_name
  
  if (event_name && event_name.length > 200) {
    errors.event_name = 'Event name should be shorter than 200 characters';
  }

  // Validation for minimum_age and maximum_age
  if (maximum_age !== "No Max" && minimum_age !== "No Min") {
    if ((parseInt(maximum_age) <= parseInt(minimum_age)) && (maximum_age !== minimum_age)) {
      errors.maximum_age = 'Max age too low';
    }
  }

  // Validation for start_date and end_date
  if (dayjs(end_date).isBefore(dayjs(start_date))) {
    errors.end_date = `The end date is before the start date`;
  }
  
  if (dayjs(start_date).isBefore(dayjs())) {
    errors.start_date = `The start date is in the past`;
  }
  
  if (dayjs(end_date).isBefore(dayjs())) {
    errors.end_date = `The end date is in the past`;
  }

  // Logic for address validation based on length
  if (street && street.length > 255) {
    errors.street = 'Street should be shorter than 255 characters';
  }
  if (street2 && street2.length > 255) {
    errors.street2 = 'Extended address should be shorter than 255 characters';
  }
  if (city && city.length > 255) {
    errors.city = 'City should be shorter than 255 characters';
  }
  if (state && state.length > 255) {
    errors.state = 'State should be shorter than 255 characters';
  }
  if (postal_code && postal_code.length > 10) {
    errors.postal_code = 'Zip code should be up to 10 digits long';
  }

  // Logic for address validation based on related fields
  if (street && (!city || !state)) {
    errors.street = 'Address should be accompanied by city and state';
  }
  if (street2 && !street) {
    errors.street2 = 'Extended address requires a primary address';
  }
  if (city && (!street || !state)) {
    errors.city = 'City requires address and state';
  }
  if (state && (!street || !city)) {
    errors.state = 'State requires address and city';
  }

  return errors
}

const EditEventForm = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const [event, setEvent] = useState(initialEventState);
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState({});
  const [dateError, setDateError] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const navigate = useNavigate();

  let { org_id, event_id } = useParams();  // Getting the organization id and event id from the route

  const getEventAPI = config.createApiUrl(
    'events', `/event/${event_id}`, config.URL_TYPES.API
  )

  const editEventAPI = config.createApiUrl(
    'events', `/event/${event_id}`, config.URL_TYPES.API
  )

  const checkSubStatusAPI = config.createApiUrl(
    'directory', `/org/${org_id}/subscription/active`, config.URL_TYPES.API
  )

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(getEventAPI);

        let eventResponse = response.data;
        
        eventResponse['address'] = eventResponse.addresses[0];

        if (eventResponse['minimum_age'] == 0) {
          eventResponse['minimum_age'] = "No Min";
        }

        if (eventResponse['maximum_age'] == 100) {
          eventResponse['maximum_age'] = "No Max";
        }

        eventResponse['org_id'] = org_id;

        setEvent(eventResponse);
        setSelectedImage(eventResponse['photo_filename'])
        setIsLoading(false);
      } catch (error) {
        console.error("An error occurred while fetching data: ", error);
        // Add additional error handling here as needed
        setIsLoading(false);
      }
    };
  
    fetchEvent();
  }, [event_id]);

  const handleSubmit = async (e) => {
    let newErrors = validatateEvent(event);
  
    // Check for empty strings on required fields
    eventFields.forEach(field => {
      if (field.required && (event[field.key] === '' || event[field.key] === null || typeof event[field.key] === 'undefined')) {
        newErrors[field.key] = `${field.label} missing`;
      }
    });
  
    // If errors exist, update the state and exit
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // Check the subscription status before creating an event
    try {
      const subscriptionRes = await axios.get(checkSubStatusAPI);
      if (subscriptionRes.status === 200 && subscriptionRes.data.preconditions_met !== true) {
        setErrors({ general: 'Unable to edit event. Subscription is not active' });
        return;
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 412) {
        let reason = 'your subscription did not renew'
        let errorMsg = 'Unable to edit event because ' + reason;
        if (err.response.data.events_available === false) {
          errorMsg += 'Event quota has been reached';
        }
        if (err.response.data.subscription_status !== true) {
          errorMsg += 'Subscription is not active';
        }
        setErrors({ general: errorMsg });
      } else {
        setErrors({ general: 'Error checking subscription status' });
      }
      return;
    }
  
    // Otherwise, proceed with the API calls to create an event
    try {
      setErrors({});
      const res = await axios.put(editEventAPI, event);
      // IDEA: Add success handling, perhaps navigate the user to the event page or clear the form
      // IDEA: Ask the user if they want to create another, modal
      navigate(`/groups/${org_id}`);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 422) {
        let errorMsg = err.response.data.ux_message;
        setErrors({ general: errorMsg });
      }
      else {
        setErrors({ general: 'Error editing events' });
      }
    }
  };

  const renderFields = (fields, values, handleInputChange, errors = {}) => {
    return fields.map(field => (
      <div className="input-group" key={field.key}>
        <div className={field.type === 'calendar' ? "mui-input-container" : "input-container"}>
          <label>
            {field.label}
          </label>
          {field.type === 'calendar' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={values[field.key] ? dayjs(values[field.key]) : null}  // Convert to Day.js object
                onChange={(newValue) => {
                  if (dayjs.isDayjs(newValue)) {  // Check if newValue is a Day.js object
                    handleInputChange(field.key, newValue.valueOf());  // Convert to timestamp
                  }
                }}
                required={field.required}
              />
            </LocalizationProvider>
          ) : field.type === 'dropdown' ? (
            <select
              value={values[field.key]}
              onChange={e => handleInputChange(field.key, e.target.value)}
              required={field.required}
            >
              <option value="">Select an option</option>
              {field.options.map(option => {
                let additonalClasses = ''; // default
                if (option === 18 || option === 21) {
                  additonalClasses += 'highlighted_ages'
                } else if (option === 'No Max' || option === 'No Min') {
                  additonalClasses += 'limit'
                }

                additonalClasses = additonalClasses.trim()

                return (
                  <option
                    className={additonalClasses}
                    value={option} 
                    key={option}
                  >
                    {option}
                  </option>
                );
              })}
            </select>
          ) : field.type === 'toggle' ? (
            <div className="toggle">
              <input
                type="checkbox"
                checked={values[field.key]}
                onChange={e => handleInputChange(field.key, e.target.checked)}
                required={field.required}
              />
              <span>{values[field.key] ? 'Yes' : 'No'}</span>
            </div>
          ) : (
            <input
              type="text"
              value={values[field.key]}
              onChange={e => handleInputChange(field.key, e.target.value)}
              required={field.required}
              />
          )}
          {errors[field.key] && <ErrorMessage errorMessage={errors[field.key]} />}
        </div>
      </div>
    ));
  };

  // Function to handle changes to input fields
  const handleInputChange = (field, value, parentKey = null) => {
      if (parentKey) {
          setEvent({
              ...event,
              [parentKey]: {
                  ...event[parentKey],
                  [field]: value,
              },
          });
      } else {
          setEvent({
              ...event,
              [field]: value,
          });
      }
  };

  if (isLoading) {
    return <Loading />
  }

  // Add a function to handle image selection
  const handleSelectImage = (image) => {
    setSelectedImage(image);
    setEvent({
        ...event,
        ['photo_filename']: image,
    });
  };

  const imageBaseUrl = "https://revibe-life-assets.s3.amazonaws.com/";

  return (
    <>
      <div className="org-event_page">
        <Modal isOpen={modalIsOpen}>
            <SearchableStockGallery handleSelectImage={handleSelectImage} selectedImage={selectedImage}/>

            <div className='event-modal'>
                <div className='org-nav'>
                    <div className="button" onClick={() => setModalIsOpen(false)}>
                      <FontAwesomeIcon
                          icon={faSave}
                          size={"xl"}
                      />
                      Change Image
                    </div>
                </div>
            </div>
        </Modal>

        <h1 className="org-title">Edit an Event</h1>
        
        <div className="org-event_image_update_container">
          <img className='selected_image'
              src={selectedImage ? imageBaseUrl + selectedImage : default01} />
            <div className="button" onClick={() => setModalIsOpen(true)}>
              <div className="fa-button">
                  <FontAwesomeIcon
                      icon={faEdit}
                      size={"1x"}
                  />
              </div>
              Change Event Image
            </div>
        </div>

        {errors['general'] && <ErrorMessage errorMessage={errors['general']} />}
        <div className='form-container org_event-fixed_wrapper'>
          <form>
            {renderFields(eventFields, event, handleInputChange, errors)}
  {/* 
            <h2 className="org-info-heading">Full Address</h2>
            IDEA: potentially re-include when handling address
            {renderFields(eventAddressFields, event.address, (field, value) =>
                handleInputChange(field, value, 'address'), errors
            )}
  */}
          </form>
        </div>
      </div>
      <div className='org-nav' style={{zIndex: 0}}>
        <Link to={`/groups/${org_id}/`}>
          <div className="fa-button">
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size={"xl"}
              />
          </div>
        </Link>
        <div onClick={() => handleSubmit('save')}>
          <div className="fa-button" style={{cursor: 'pointer'}}>
              <FontAwesomeIcon
                  icon={faSave}
                  size={"xl"}
              />
          </div>
        </div>
      </div>
    </>
  );
}

export default EditEventForm;
