/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { searchLookup } from '../../helpers/search';

import StockImageLookup from '../../data/StockImageLookup.json';
import MultiSelectOptions from '../../data/MultiSelectOptions.json';
import default01 from './images/default01.png';

import PictureGallery from '../PictureGallery';

import './SearchableStockGallery.css';

const animatedComponents = makeAnimated();

const categories = [
  "Art",
  "Technology",
  "Social",
  "Nature",
  "Education",
  "Other"
];

// Standardize categories for internal use while preserving the original format for display
const standardizedCategories = categories.reduce((acc, category) => {
  acc[category.toLowerCase()] = category;
  return acc;
}, {});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: '0.75rem',
    marginBottom: '1rem',
    outline: 'none',
    border: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: (state.isFocused || state.isActive) ? 'var(--swatch-10)' : 'var(--swatch-12)',
  }),
};

const SearchableStockGallery = ({handleSelectImage, selectedImage}) => {
  // const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchImageResults, setSearchImageResults] = useState([]);

  // // Add a function to handle image selection
  // const handleSelectImage = (image) => {
  //   setSelectedImage(image);
  // };

  // Handle change in search term input
  const handleSearchTermChange = (event) => {
    // The search term is the join of multiple selections
    setSearchTerm(event.map(t => t.value).join(" "))
  };

  // Handle change in category checkboxes
  const handleCategoryChange = (category, isChecked) => {
    setSelectedCategories(prevCategories => 
      isChecked ? [...prevCategories, category] : prevCategories.filter(cat => cat !== category)
    );
  };

  // Update search results when searchTerm or selectedCategories change
  useEffect(() => {
    const result = searchLookup(searchTerm, selectedCategories, StockImageLookup);
    setSearchImageResults(result);
  }, [searchTerm, selectedCategories]);

  const imageBaseUrl = "https://revibe-life-assets.s3.amazonaws.com/";

  return (
    <div className='searchable-stock-gallery'>
      <div className='card'>
        <img className='selected_image'
             src={selectedImage ? imageBaseUrl + selectedImage : default01} />
        <h5 className='selected_image-caption'>
          Select an image to change the default image
        </h5>
      </div>
                  
      <div>
        {searchImageResults.length > 0 && 
          <>
            <h6 className='disclaimer'>
              Disclaimer: Event visuals use <br />AI-generated stock images.
            </h6>
            <div className='card'>
              <div className='search-picture_gallery_container'>
                <PictureGallery selectedImage={selectedImage} onSelectImage={handleSelectImage} images={searchImageResults}/>
              </div>
            </div>
          </>
        }

        {searchImageResults.length === 0 && 
          <h6 className='search-empty_text'>Choose a tag or select a category to see other images</h6>
        }
      </div>

      {/* Add input field for search term */}
      <h6>Choose a tag</h6>
      <div className="search_input-container">
        <Select
          onChange={handleSearchTermChange} 
          placeholder="Search images" 
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={MultiSelectOptions}
          styles={customStyles}
        />
      </div>
      {/* Input field and other components remain the same */}
      <h6>Or, select a category</h6>
      <div className='search_categories card'>
        {Object.entries(standardizedCategories).map(([key, displayCategory]) => (
          <label className="search_category" key={key}>
            <input 
              type="checkbox" 
              checked={selectedCategories.includes(key)}
              onChange={(e) => handleCategoryChange(key, e.target.checked)}
            />
            {displayCategory}
          </label>
        ))}
      </div>
    </div>
  );
};

export default SearchableStockGallery;
