/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';

import './AnimationExamples.css'
import ErrorMessage from '../ErrorMessage';

const animations = [
  { name: 'Scale Up', class: 'scaleUpAnimation' },
  { name: 'Slide (Right)', class: 'slideAnimation' },
  { name: 'Fade', class: 'fadeAnimation' },
  { name: 'Pulse', class: 'pulseAnimation' },
  { name: 'Rotate', class: 'rotateAnimation' },
  { name: 'Blink', class: 'blinkAnimation' },
  { name: 'Bounce', class: 'bounceAnimation' },
  { name: 'Flip', class: 'flipAnimation' },
  { name: 'Swing', class: 'swingAnimation' },
  { name: 'Shake', class: 'shakeAnimation' },
  { name: 'Glow', class: 'glowAnimation' },
  { name: 'Expand', class: 'expandAnimation' },
];

const AnimatedBackgroundTemplate = ({ animationName, duration, color }) => {
  const style = {
    '--animation-duration': `${duration}s`,
    '--animation-color': color,
  };

  return (
    <div className={`animatedBackground ${animationName}`} style={style}>
    
    </div>
  );
};

const SlidingDiagonal = ({
  copy,
  animationSpeed = '3s',
}) => {
  return (
    <div className="sliding-animation-container">
      <div className="bg" style={{ animationDuration: animationSpeed }}></div>
      <div className="bg bg2" style={{ animationDuration: `calc(${animationSpeed} + 1s)` }}></div>
      <div className="bg bg3" style={{ animationDuration: `calc(${animationSpeed} + 2s)` }}></div>
      <div className="bg bg2" style={{ animationDuration: `calc(${animationSpeed} + 5s)` }}></div>
      <div className="sliding-animation-copy">
        <div>{copy}</div>
      </div>
    </div>
  )
}

const MovingBackgroundGradient = ({
  copy,
}) => {
  return (
    <div className="moving-gradient-background">
      <div className="moving-gradient-copy">
        {copy}
      </div>
    </div>
  )
}

const AnimationExamples = () => {
  const [selectedAnimation, setSelectedAnimation] = useState(animations[0].class);

  const handleAnimationChange = (event) => {
    const animationClass = animations.find(animation => animation.name === event.target.value).class;
    setSelectedAnimation(animationClass);
  };

  return (
    <div className="animation-examples">
      <h1>Animations</h1>
      <h3>Simple Examples</h3>
      <h5>Simple animations, applied to a reusable template</h5>
      <div className='card' style={{padding: "1rem"}}>
        <div className='input-container'>
          <select onChange={handleAnimationChange} defaultValue="Slide">
            {animations.map(animation => (
              <option key={animation.name} value={animation.name}>{animation.name}</option>
              ))}
          </select>
        </div>
          
        <h6>Choose among different options. How might these be improved?</h6>

        <div id='animation-simple-examples'>
          <AnimatedBackgroundTemplate 
            animationName={selectedAnimation} 
            duration={2} 
            color="var(--swatch-09)"
          />
          <label>2 Sec</label>
          
          <AnimatedBackgroundTemplate 
            animationName={selectedAnimation} 
            duration={3} 
            color="var(--swatch-10)"
          />
          <label>3 Sec</label>
            
          <AnimatedBackgroundTemplate 
            animationName={selectedAnimation} 
            duration={5} 
            color="var(--swatch-12)"
            />
          <label>5 Sec</label>
        </div>
        
        <h6>How often will these occur at the same time?</h6>
          
      </div>
      <h3>Custom Examples</h3>
      <h5>Custom animations, where each is a component</h5>
      <div>
        <ErrorMessage errorMessage={'Revibe Life'} />
        <SlidingDiagonal copy={'Revibe Life'} animationSpeed={'5s'} />
        <MovingBackgroundGradient copy={'Revibe Life'} />
      </div>
    </div>
  );
};

export default AnimationExamples;
