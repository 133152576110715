/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';
import { Link } from 'react-router-dom';


import EventList from '../EventList';

function OrgEventSummary({ org }) {
    return (
        <div className='org-event_list'>
            <div>
                <EventList id={org.id} />
            </div>
        </div>
    );
}

export default OrgEventSummary;
