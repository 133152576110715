/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
// 'searchLookup' Example Usage
// console.log(searchLookup("green basketball", ["Nature", "Sports"], lookup));

// Function to search images by tags and categories
export function searchLookup(inputTags, selectedCategories, lookup) {
    const tags = inputTags.toLowerCase().split(' '); // Split input tags into individual tags
    let results = new Set();

    if (!lookup) {
        return new Set()
    }
    Object.keys(lookup).forEach(imageName => {
        const imageInfo = lookup[imageName];

        // Check if any of the tags match
        const tagMatch = tags.some(tag => imageInfo.tags.includes(tag));

        // Check if any of the categories match
        const categoryMatch = selectedCategories.some(category => 
            imageInfo.categories.includes(category));

        if (tagMatch || categoryMatch) {
            results.add(imageName);
        }
    });

    return Array.from(results);
}
