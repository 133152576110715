/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';

import './StockImage.css'

const imageBaseUrl = "https://revibe-life-assets.s3.amazonaws.com/";

const StockImage = ({ profilePicture, onSelectImage, selectedImage }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // Initialize or update the image URL
    const newImageUrl = profilePicture ? imageBaseUrl + profilePicture : '';
    setImageUrl(newImageUrl);
  }, [profilePicture]); // Empty dependency array for componentDidMount behavior

  return (
    <img 
      className={`profile_image ${selectedImage == profilePicture ? 'selected' : ''}`}
      onClick={() => onSelectImage(profilePicture)}
      src={imageUrl}
      alt={`Profile Picture`} />
  );
};
export default StockImage;
