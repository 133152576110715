/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';


import GetStarted from './pages/GetStarted/GetStarted';
import OrgList from './pages/OrgList/OrgList';
import OrgPage from './pages/OrgPage/OrgPage';
import OrgDetails from './pages/OrgDetails/OrgDetails';

import OrgSignupForm from './components/OrgSignupForm';
import OrgEditForm from './components/OrgEditForm';
import CreateEventForm from './components/CreateEventForm';
import OrgSubscribe from './pages/OrgSubscribe/OrgSubscribe';
import EditEventForm from './components/EditEventForm/EditEventForm';

import AnimationExamples from './components/AnimationExamples/AnimationExamples';
import SearchableStockGallery from './components/SearchableStockGallery/SearchableStockGallery';

// Create a custom hook to encapsulate the logic for determining if the nav should be hidden
const useHideNav = (rootPaths) => {
  const location = useLocation(); // Hook to get the current location object
  const hideNav = window.location.pathname === '/' || rootPaths.some(path => window.location.pathname.startsWith(path));
  return hideNav;
};

const AppContent = () => {
  const rootPaths = []; // Add other root paths you want to check
  const hideNav = useHideNav(rootPaths);

  useEffect(() => {
    const adjustHeight = () => {
      const mainElement = document.getElementById('main');
      if (mainElement) {
        const navHeight = hideNav ? 0 : 3 * 16; // 3rem to pixels
        mainElement.style.height = `${window.innerHeight - navHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener('resize', adjustHeight);
    
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, [hideNav]);

  return (
    <div style={{ display: "flex", width: "100vw" }}>
      <div id="main">
        <div className='content'>
          {/* Your Routes here */}
          <Routes>
            <Route path="/" element={<GetStarted />} />
            <Route path="/groups" element={<OrgList />} />
            <Route path="/groups/:id" element={<OrgDetails />} />
            <Route path="/groups/new" element={<OrgSignupForm />} />
            <Route path="/groups/:id/edit" element={<OrgEditForm />} />
            <Route path="/groups/:id/page" element={<OrgPage />} />
            <Route path="/groups/:id/subscribe" element={<OrgSubscribe />} />
            <Route exact path='/groups/:id/newevent' element={<CreateEventForm />} />
            <Route exact path='/groups/:org_id/editevent/:event_id' element={<EditEventForm />} />
            <Route path="/public/groups/:id" element={<OrgPage />} />
            <Route path="/stock" element={<SearchableStockGallery />} />
            <Route path="/animation-examples" element={<AnimationExamples />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  console.log('Directory')
  
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
