/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import axios from "axios";
import { Link, useParams, useNavigate } from 'react-router-dom';

import config from '../../config';
import default01 from './images/default01.png';

import ErrorMessage from '../../components/ErrorMessage';
import SearchableStockGallery from '../../components/SearchableStockGallery';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faSave, faRotate } from '@fortawesome/free-solid-svg-icons';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import './CreateEventForm.css'

const initialEventState = {
  event_name: "",
  start_date: "",
  end_date: "",
  minimum_age: "",
  maximum_age: "",
  registration_required: false,
  specific_location: "",
  description: "",
  photo_filename: null,
  address: {
    city: "",
    postal_code: "",
    state: "",
    street: "",
    street2: ""
  }
};

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

const eventFields = [
  { label: 'Event Name', key: 'event_name', required: true, type: 'text' },
  { label: 'Location', key: 'specific_location', required: true },
  { label: 'Start Date', key: 'start_date', required: true, type: 'calendar' },
  { label: 'End Date', key: 'end_date', required: true, type: 'calendar' },
  { label: 'Description', key: 'description', required: true },
  { label: 'Min Age', key: 'minimum_age', required: true, type: 'dropdown', options: ['No Min', ...Array(55).keys()]  },
  { label: 'Max Age', key: 'maximum_age', required: true, type: 'dropdown', options: ['No Max', ...Array(55).keys(), '55+'].filter(item => item !== 0)  },
  // ... other fields you may have
];

const eventAddressFields = [
  { label: 'Address', key: 'street', required: false },
  { label: 'Extended Address', key: 'street2', required: false },
  { label: 'City', key: 'city', required: false },
  { label: 'State', key: 'state', required: false, type: 'dropdown', options: states },
  { label: 'Zip Code', key: 'postal_code', required: true },
  // ... other fields
];


const icons = {
  // Add other icons here as needed
};

const validatateEvent = (event) => {
  const { event_name, start_date, end_date, minimum_age, maximum_age } = event;
  const { street, street2, city, state, postal_code } = event.address;

  let errors = {};

  // Validation for event_name
  
  if (event_name && event_name.length > 200) {
    errors.event_name = 'Event name should be shorter than 200 characters';
  }

  // Validation for minimum_age and maximum_age
  if (maximum_age !== "No Max" && minimum_age !== "No Min") {
    if ((parseInt(maximum_age) <= parseInt(minimum_age)) && (maximum_age !== minimum_age)) {
      errors.maximum_age = 'Max age too low';
    }
  }

  // Validation for start_date and end_date
  if (dayjs(end_date).isBefore(dayjs(start_date))) {
    errors.end_date = `The end date is before the start date`;
  }
  
  if (dayjs(start_date).isBefore(dayjs())) {
    errors.start_date = `The start date is in the past`;
  }
  
  if (dayjs(end_date).isBefore(dayjs())) {
    errors.end_date = `The end date is in the past`;
  }

  // Logic for address validation based on length
  if (street && street.length > 255) {
    errors.street = 'Street should be shorter than 255 characters';
  }

  if (street2 && street2.length > 255) {
    errors.street2 = 'Extended address should be shorter than 255 characters';
  }
  if (city && city.length > 255) {
    errors.city = 'City should be shorter than 255 characters';
  }
  if (state && state.length > 255) {
    errors.state = 'State should be shorter than 255 characters';
  }
  if (postal_code && postal_code.length > 10) {
    errors.postal_code = 'Zip code should be up to 10 digits long';
  }

  // Logic for address validation based on related fields
  if (street && (!city || !state)) {
    errors.street = 'Address should be accompanied by city and state';
  }
  if (street2 && !street) {
    errors.street2 = 'Extended address requires a primary address';
  }
  if (city && (!street || !state)) {
    errors.city = 'City requires address and state';
  }
  if (state && (!street || !city)) {
    errors.state = 'State requires address and city';
  }

  return errors
}

const DIRECTORY_BASE_URL = config.createApiUrl('directory', '/groups/', config.URL_TYPES.BASE);

const CreateEventForm = () => {
  const [org, setOrg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedImage, setSelectedImage] = useState(null);

  const [event, setEvent] = useState(initialEventState);

  const [errors, setErrors] = useState({});
    
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const navigate = useNavigate();

  let { id } = useParams();  // Getting the organization id from the route

  const createEventAPI = config.createApiUrl(
    'events', `/event/org/${id}`, config.URL_TYPES.API
  )

  useEffect(() => {
      setIsLoading(true);

      const fetchOrgDetails = async () => {
          const api = config.createApiUrl('directory', `/org/${id}/full-details`, config.URL_TYPES.API);

          try {
              const orgRes = await axios.get(api);
              let fullOrg = orgRes.data;
              setOrg(fullOrg);
          } catch (err) {
            // IDEA: Handle error
              console.error(err);
          }
          setIsLoading(false);
      };

      fetchOrgDetails()
  }, []);

  const handleSubmit = async (e) => {
    let newErrors = validatateEvent(event);
  
    // Check for empty strings on required fields
    eventFields.forEach(field => {
      if (field.required && (event[field.key] === '' || event[field.key] === null || typeof event[field.key] === 'undefined')) {
        newErrors[field.key] = `${field.label} missing`;
      }
    });

    // IDEA: Make this show up at the bottom above the nav
    for (let i in newErrors) {
      break;
    }
  
    // If errors exist, update the state and exit
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    // Otherwise, proceed with the API calls to create an event
    try {
      setErrors({});
      const res = await axios.post(createEventAPI, event);
      console.log(res.data);
      // IDEA: Add success handling, perhaps navigate the user to the event page or clear the form
      // IDEA: Ask the user if they want to create another, modal
      navigate(`/groups/${id}`);
    } catch (err) {
      console.error(err);
      if (err.response?.data) {
        if (err.response.data.message == "Failed to create event: quota exceeded") {
          setErrors({ general: 'Sorry, try again later, this group created 100 events this week' });
        } else if (err.response.status == 503 ) {
          setErrors({ general: 'There\'s a lot of activity right now, try again in a second or two.' });
        } else if (err.response.status == 429) {
          setErrors({ general: 'Let\'s create a slower vibe. Try again in a few minutes.' });
        } else if (err.response.status == 412 && err.response.data.message == "Preconditions not met") {
          setErrors({ general: 'Sorry, your subscription is not active' });
        } else {
          setErrors({ general: 'Error creating event' });
        }
      } else {
        setErrors({general: 'Event service is not running. Try in again in a few minutes'});
        return;
      }
    }
  };

  const renderFields = (fields, values, handleInputChange, errors = {}) => {
    return fields.map(field => (
      <div className="input-group" key={field.key}>
        <div className={field.type === 'calendar' ? "mui-input-container" : "input-container"}>
          <label>
            {field.label}
          </label>
          {field.type === 'calendar' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={values[field.key] ? dayjs(values[field.key]) : null}  // Convert to Day.js object
                onChange={(newValue) => {
                  if (dayjs.isDayjs(newValue)) {  // Check if newValue is a Day.js object
                    handleInputChange(field.key, newValue.valueOf());  // Convert to timestamp
                  }
                }}
                required={field.required}
              />
            </LocalizationProvider>
          ) : field.type === 'dropdown' ? (
            <select
              value={values[field.key]}
              onChange={e => handleInputChange(field.key, e.target.value)}
              required={field.required}
            >
              <option value="">Select an option</option>
              {field.options.map(option => {
                let additonalClasses = ''; // default
                if (option === 18 || option === 21) {
                  additonalClasses += 'highlighted_ages'
                } else if (option === 'No Max' || option === 'No Min') {
                  additonalClasses += 'limit'
                }

                additonalClasses = additonalClasses.trim()

                return (
                  <option
                    className={additonalClasses}
                    value={option} 
                    key={option}
                  >
                    {option}
                  </option>
                );
              })}
            </select>
          ) : field.type === 'toggle' ? (
            <div className="toggle">
              <input
                type="checkbox"
                checked={values[field.key]}
                onChange={e => handleInputChange(field.key, e.target.checked)}
                required={field.required}
              />
              <span>{values[field.key] ? 'Yes' : 'No'}</span>
            </div>
          ) : (
            <input
              type="text"
              value={values[field.key]}
              onChange={e => handleInputChange(field.key, e.target.value)}
              required={field.required}
              />
          )}
          {errors[field.key] && <ErrorMessage errorMessage={errors[field.key]} />}
        </div>
      </div>
    ));
  };

  // Function to handle changes to input fields
  const handleInputChange = (field, value, parentKey = null) => {
    if (parentKey) {
        setEvent({
            ...event,
            [parentKey]: {
                ...event[parentKey],
                [field]: value,
            },
        });
    } else {
        setEvent({
            ...event,
            [field]: value,
        });
    }
  };

  // Add a function to handle image selection
  const handleSelectImage = (image) => {
    setSelectedImage(image);
    setEvent({
        ...event,
        ['photo_filename']: image,
    });
  };

  // IDEA: Refactor to a common utility
  function truncateCharacters(str, maxLength) {
      if (str.length <= maxLength) {
        return str; // Returns original string if string is equal to or less than max length
      } else {
        const truncatedString = str.substring(0, maxLength); // Get the substring up to the maxLength
        // Find the last space within the truncated substring to avoid cutting off a word
        const lastSpaceIndex = truncatedString.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
          return truncatedString.substring(0, lastSpaceIndex) + '...'; // Add dots at the last space
        } else {
          return truncatedString + '...'; // If no space found, add dots at the end of the substring
        }
      }
  }

  const imageBaseUrl = "https://revibe-life-assets.s3.amazonaws.com/";

  return (
    <>
      <div className="org-event_page">
        <Modal isOpen={modalIsOpen}>
            <SearchableStockGallery handleSelectImage={handleSelectImage} selectedImage={selectedImage}/>

            <div className='event-modal'>
                <div className='org-nav'>
                    <div className="button" onClick={() => setModalIsOpen(false)}>
                      <FontAwesomeIcon
                          icon={faSave}
                          size={"xl"}
                      />
                      Change Image
                    </div>
                </div>
            </div>
        </Modal>

        {org &&
          <>
              <div className='connected-event_group'>
                  <div className='connected-event_group-info'>
                      <h6 className='connected_vibe_label'>
                          {truncateCharacters(org.name, 24)}
                      </h6>
                  </div>

                  <Link to={`${DIRECTORY_BASE_URL}`}>
                      <div className='button gradient_button'>
                          <FontAwesomeIcon icon={faRotate} />
                          Switch
                      </div>
                  </Link>
              </div>
          </>
        }

        <h1 className="org-title">Create an Event</h1>

        <div className="org-event_image_update_container">
          <img className='selected_image'
              src={selectedImage ? imageBaseUrl + selectedImage : default01} />
            <div className="button" onClick={() => setModalIsOpen(true)}>
              <div className="fa-button">
                  <FontAwesomeIcon
                      icon={faEdit}
                      size={"1x"}
                  />
              </div>
              Change Event Image
            </div>
        </div>

        {errors['general'] && <ErrorMessage errorMessage={errors['general']} />}
        <div className='form-container org_event-fixed_wrapper'>
          <form>
            {renderFields(eventFields, event, handleInputChange, errors)}
  {/*           
            <h2 className="org-info-heading">Full Address</h2>
            IDEA: potentially re-include when handling address
            {renderFields(eventAddressFields, event.address, (field, value) =>
                handleInputChange(field, value, 'address'), errors
            )}
  */}
          </form>
        </div>
      </div>
      <div className='org-nav' style={{zIndex: 0}}>
        <Link to={`/groups/${id}/`}>
          <div className="fa-button">
              <FontAwesomeIcon
                  icon={faArrowLeft}
                  size={"xl"}
              />
          </div>
        </Link>
        <div onClick={() => handleSubmit('save')}>
          <div className="fa-button" style={{cursor: 'pointer'}}>
              <FontAwesomeIcon
                  icon={faSave}
                  size={"xl"}
              />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEventForm;
