/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';

import Loading from '../Loading';

const OrgHighlight = ({ org, eventInfo, children }) => {
    const [isLoading, setIsLoading] = useState(false);

    if (isLoading) {
        return <Loading />
    }

    if (!org) {
        return <div>No organization found</div>
    }

    const hasSubscriptionDetails = 'subscription' in org && org.subscription != null;
    const subscription = org.subscription;

    const optionsDate = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };

    // IDEA: Refactor to a utility file for all dates in app, assume milliseconds
    const formatDateFromMilliseconds = (inputDate) => {
        const fDate = new Date(inputDate * 1000).toLocaleDateString('en-US', optionsDate);
        
        // Remove leading zero from hours if present
        return fDate.replace(/^0(?:0:)?/, '');
    }

    const optionsShortDate = { month: 'numeric', day: 'numeric' };

    const formatShortDateFromMilliseconds = (inputDate) => {
        const fDate = new Date(inputDate).toLocaleDateString('en-US', optionsShortDate);
        
        // Remove leading zero from hours if present
        return fDate.replace(/^0(?:0:)?/, '');
    }

    return (
        <div className='card'>
            {org.hide_date && 
                <span>
                    <b>Hidden</b>
                </span>
            }
            <h4 className='org-list-label'>
                {org.name}
            </h4>
            <h6>
                {org.contact_email}
            </h6>
            {hasSubscriptionDetails &&
                <div className='org-page_details'>
                    <div className='org-list-label_subscription-details'>
                        Subscription Details
                    </div>
                    <div className='event_org_info_item'>
                        <p className='event_org_info_item_wrapper'>
                            <span className='event_org_info_label'>Bill Day</span>
                        </p>
                        <p className='event_org_info_value'>
                            {formatDateFromMilliseconds(subscription?.current_period_end)}
                        </p>
                    </div>
                </div>
            }
            {eventInfo &&
                <div className='org-page_details'>
                    <div className='org-list-label_subscription-details'>
                        Recent Events
                    </div>
                    <div className='event_org_info_item'>
                        <p className='event_org_info_item_wrapper'>
                            <span className='event_org_info_label'>
                                Created
                            </span>
                        </p>
                        <p className='event_org_info_value'>
                            {
                                eventInfo.event_count === 1 ? 
                                `${eventInfo.event_count} Event` :
                                `${eventInfo.event_count} Events`
                            } 
                        </p>
                    </div>
                    <div className='event_org_info_item'>
                        <p className='event_org_info_item_wrapper'>
                            <span className='event_org_info_label'>
                                During
                            </span>
                        </p>
                        <p className='event_org_info_value'>
                            {formatShortDateFromMilliseconds(eventInfo?.startDate)} - {formatShortDateFromMilliseconds(eventInfo?.endDate)}
                        </p>
                    </div>
                </div>
            }
            <div className='org-more'>
                {children}
            </div>
        </div>
    );
};

export default OrgHighlight;
