/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';

import config from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faSave, faBox, faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const initialOrgState = {
    id: '',
    name: '',
    contact_email: '',
    contact_phone: '',
    is_subscription_active: true, // Default to true or false depending on your use case
    overview: '',
    description: '',
    website_url: '',
    logo: '',
    primary_category: '',
    media_handles: {
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: ''
    },
    address: {
        city: '',
        country: '',
        phone_number: '',
        postal_code: '',
        state: '',
        street: '',
        street2: ''
    }
};

const primaryCategories = [
    'Arts',
    'Business',
    'Health',
    'Recreation',
    'Social',
    // Add more categories as needed
];

const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
];

const organizationFields = [
    { label: 'Group Name', key: 'name', required: true },
    { label: 'Email', key: 'contact_email', required: true },
    { label: 'Phone', key: 'contact_phone', required: false },
  ];
  
const organizationDetailsFields = [
    { label: 'Overview', key: 'overview', required: false },
    { label: 'Description', key: 'description', required: false },
    { label: 'Website URL', key: 'website_url', required: false },
    { label: 'Main Focus', key: 'primary_category', required: true, type: 'dropdown', options: primaryCategories },
];

const organizationAddressFields = [
    { label: 'Address', key: 'street', required: false },
    { label: 'Extended Address', key: 'street2', required: false },
    { label: 'City', key: 'city', required: false },
    { label: 'State', key: 'state', required: true, type: 'dropdown', options: states },
    { label: 'Zip Code', key: 'postal_code', required: true },
];

// Social Media Platform Icons
const icons = {
    facebook: faFacebook,
    twitter: faXTwitter,
    instagram: faInstagram,
    linkedin: faLinkedin,
    // Add other icons here as needed
};

const organizationHandlesFields = [
    { key: 'facebook', label: 'Facebook', icon: 'facebook' },
    { key: 'twitter', label: 'Twitter', icon: 'twitter' },
    { key: 'instagram', label: 'Instagram', icon: 'instagram' },
    { key: 'linkedin', label: 'LinkedIn', icon: 'linkedin' },
    // More fields here
];


const OrgEditForm = () => {
    const [org, setOrg] = useState(initialOrgState); // Single state variable for the entire organization
    const [isLoading, setIsLoading] = useState(true);
    
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    
    let { id } = useParams();  // Getting the organization id from the route
    
    const getOrgApi = config.createApiUrl('directory', `/org/${id}/full-details`, config.URL_TYPES.API);
    const updateOrgApi = config.createApiUrl('directory', `/org/${id}`, config.URL_TYPES.API);
    const hideApi = config.createApiUrl('directory', `/org/${id}/state:hide`, config.URL_TYPES.API);
    const showApi = config.createApiUrl('directory', `/org/${id}/state:show`, config.URL_TYPES.API);
    const thisGroupHomeURL= config.createApiUrl('directory', `/groups/${id}/`, config.URL_TYPES.BASE);
    const viewPageURL = config.createApiUrl('directory', `/groups/${id}/page`, config.URL_TYPES.BASE);

    useEffect(() => {
        const getFullDetails = async () => {
            try {
                const response = await axios.get(getOrgApi);
                // Create a new object where all null values are replaced with empty strings
                const cleanedResponse = Object.fromEntries(
                    Object.entries(response.data).map(([key, value]) => {
                        if (key === 'hide_date') {
                            return [key, value];
                        }
                        if (value === null) {
                            return [key, ''];
                        } else if (typeof value === 'object') {
                            // For nested objects such as address and media_handles
                            return [
                                key,
                                Object.fromEntries(
                                    Object.entries(value).map(([subKey, subValue]) => [subKey, subValue === null ? '' : subValue])
                                ),
                            ];
                        }
                        return [key, value];
                    })
                );
                setOrg(cleanedResponse);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                // Handle the error
            }
        };
    
        getFullDetails();
    }, [getOrgApi]);

    const handleHideConfirmation = async () => {
            try {
                await axios.patch(hideApi);
                window.location.href = `${thisGroupHomeURL}`;
            } catch (error) {
                console.error(error);
                setErrorMessage('Failed to hide organization.');
            }
        };
        
    const handleHide = () => {
        setModalIsOpen(true);
    };
      
    const handleShow = async () => {
        try {
          await axios.patch(showApi);
          // You can redirect or show a success message here
          window.location.href = `${thisGroupHomeURL}`;    
        } catch (error) {
          console.error(error);
          setErrorMessage('Failed to show organization.');
        }
    };
      
    const handleSubmit = async (action) => {
        try {
            const response = await axios.put(updateOrgApi, {
                org: org,
            });

            // If organization creation is successful
            if (response.status === 201) {
                console.log('Organization updated successfully');
                
                let redirectUrl = `${thisGroupHomeURL}`;
                if (action === 'save_view') {
                    redirectUrl = `${viewPageURL}`;
                }

                window.location.href = redirectUrl;
                
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setErrorMessage(error.response.data.message);  // Display the error message from server
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage(error.message);
            }
        }
    };

    // Function to handle changes to input fields
    const handleInputChange = (field, value, parentKey = null) => {
        if (parentKey) {
            setOrg({
                ...org,
                [parentKey]: {
                    ...org[parentKey],
                    [field]: value,
                },
            });
        } else {
            setOrg({
                ...org,
                [field]: value,
            });
        }
    };

    const renderFields = (fields, values, handleInputChange) => {
        return fields.map(field => (
            <div className="input-group" key={field.key}>
                <div className="input-container">
                    <label>
                        {field.icon && <FontAwesomeIcon icon={icons[field.icon]} />} {field.label}
                    </label>
                    {field.type === 'dropdown' ? (
                        <select
                            value={values[field.key]}
                            onChange={e => handleInputChange(field.key, e.target.value)}
                            required={field.required}
                        >
                            <option value="">Select an option</option>
                            {(field.options || []).map(option => (
                                <option value={option} key={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input
                            type="text"
                            value={values[field.key]}
                            onChange={e => handleInputChange(field.key, e.target.value)}
                            required={field.required}
                        />
                    )}
                </div>
            </div>
        ));
    };

    const formatDate = (inputDate) => {
        /*
        *
        * IDEA: Refactor this into a helper component, to manage the state
        * across multiple components
        * 
        */

        // Reset Date Format
        const optionsDate = {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        const fDate = new Date(inputDate * 1000).toLocaleDateString('en-US', optionsDate);
        return fDate;
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Modal isOpen={modalIsOpen}>
                <div className='org-modal'>
                    <ErrorMessage errorMessage={"Warning: Hiding this group from public view restricts access. Your subscription is active until you cancel it and we will continue to bill you for any events used."} />
                    <div style={{ padding: "1rem" }}>
                        <h1 className="org-title">Hide Group?</h1>
                        <h5 style={{ margin: 0 }}>Are you sure you want to hide this organization?</h5>
                    </div>
                    <div className='org-nav' style={{fontWeight: "var(--weight-03)"}}>
                        <div className="button" onClick={handleHideConfirmation}>Yes</div>
                        <div className="button" onClick={() => setModalIsOpen(false)}>Cancel</div>
                    </div>
                </div>
            </Modal>

            <h1 className="org-title">Edit Your Group Profile</h1>
            {org.hide_date === null &&
                <div className='org-callout'>
                    <h6>
                        <b>
                        Explore how your group looks to everyone else!
                        </b>
                    </h6>
                    <div onClick={() => handleSubmit('save_view')} className="button">
                        Let's go!
                    </div>
                </div>
            }
            {org.hide_date !== null &&
                <div className='org-callout'>
                    <h6 style={{textAlign: "center", margin: 0}}>
                        <b>
                            This group is currently hidden as of {formatDate(org.hide_date)}
                        </b>
                    </h6>
                </div>
            }
            <div className='form-container org-fixed_wrapper'>
                <form>
                    {errorMessage &&
                        <ErrorMessage errorMessage={errorMessage} />
                    }
                    <div className='card'>
                        <h3 className='org-info-heading'>About</h3>
                        {renderFields(organizationFields, org, handleInputChange)}
                    </div>
                    <div className='card'>
                        <h3 className='org-info-heading'>Details</h3>
                        {renderFields(organizationDetailsFields, org, handleInputChange)}
                    </div>
                    <div className='card'>
                    <h3 className='org-info-heading'>Location</h3>
                        {renderFields(organizationAddressFields, org.address, (field, value) =>
                            handleInputChange(field, value, 'address')
                        )}
                    </div>
                    <div className='card'>
                        <h3 className='org-info-heading'>Connect</h3>
                        {renderFields(organizationHandlesFields, org.media_handles, (field, value) =>
                            handleInputChange(field, value, 'media_handles')
                        )}
                    </div>
                    {errorMessage &&
                        <ErrorMessage errorMessage={errorMessage} />
                    }
                </form>
            </div>
            <div className='org-nav' style={{zIndex: 0}}>
                <Link className="fa-button" to={`/groups/${org.id}/`}>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        size={"xl"}
                    />
                </Link>
                <div onClick={() => handleSubmit('save')}
                     className="fa-button">
                    <FontAwesomeIcon
                        icon={faSave}
                        size={"xl"}
                    />
                </div>
                {org.hide_date === null &&
                    <div onClick={handleHide}
                         className="fa-button">
                        <FontAwesomeIcon
                            icon={faBox}
                            size={"xl"}
                        />
                    </div>
                }
                {org.hide_date !== null &&
                    <div onClick={handleShow}
                         className="fa-button">
                        <FontAwesomeIcon
                            icon={faBoxOpen}
                            size={"xl"}
                        />
                    </div>
                }
            </div>
        </>
    );
  }
  
  export default OrgEditForm;
