/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';

import Loading from '../../components/Loading';
import ErrorMessage from '../../components/ErrorMessage';

import config from '../../config';

import './OrgSubscribe.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const apiStripeCheckout = config.createApiUrl('directory', `/billing/create-checkout-session`, config.URL_TYPES.API);
const apiStripePortal = config.createApiUrl('directory', `/billing/customer-portal`, config.URL_TYPES.API);

const ProductDisplay = ({ subscriptionDetails }) => {

    let { id } = useParams();

    const loadCheckout = () => {
        axios.post(`${apiStripeCheckout}`, {
            org_id: id
        }, {
            // This function defines whether the response should be resolved or rejected
            validateStatus: function (status) {
                // Resolve only if status is less than 400 (i.e., no client or server error responses)
                return status < 400; 
            }
        })
        .then(response => {
            // If the status code was 303, the URL to redirect to might be in the 'Location' header
            if (response.status === 303) {
                const redirectURL = response?.data?.session_url
                if (redirectURL) {
                    window.location.href = redirectURL;
                }
            }
        })
        .catch(error => {
            console.error("Error loading checkout:", error);
        });
    };
    
    const loadPortal = () => {
        axios.post(`${apiStripePortal}`, {
            org_id: id
        }, {
            // This function defines whether the response should be resolved or rejected
            validateStatus: function (status) {
                // Resolve only if status is less than 400 (i.e., no client or server error responses)
                return status < 400; 
            }
        })
        .then(response => {
            // If the status code was 303, the URL to redirect to might be in the 'Location' header
            if (response.status === 303) {
                const redirectURL = response?.data?.session_url
                if (redirectURL) {
                    window.location.href = redirectURL;
                }
            }
        })
        .catch(error => {
            console.error("Error loading checkout:", error);
        });
    };
    
    // Loading the sessions from Stripe
    return (
        <>
            {!subscriptionDetails &&
                <div onClick={() => loadCheckout()} className='button'>
                    Subscribe
                </div>
            }
            {subscriptionDetails && (subscriptionDetails?.canceled_at !== null) &&
                <div onClick={() => loadCheckout()} className='button'>
                    Subscribe
                </div>
            }
            {subscriptionDetails && (subscriptionDetails?.canceled_at === null) &&
                <div onClick={() => loadPortal()} className='button'>
                    Update Subscription
                </div>
            }
        </>
    )
};
  
const OrgSubscribe = () => {
    // Stripe Code

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    // 
    // Rest of code
    // 

    const [subscriptionDetails, setSubscriptionDetails] = useState(null);

    let { id } = useParams();

    const apiSubscriptionDetails = config.createApiUrl('directory', `/org/${id}/subscription/details`, config.URL_TYPES.API);

    useEffect(() => {
        setIsLoading(true);
        axios.get(apiSubscriptionDetails)
            .then(res => {
                setSubscriptionDetails(res.data.subscription_details);
                setIsLoading(false);
            })
            .catch(err => {
                if (err && err?.response?.status != 404) {
                    console.error(err);
                    setErrorMessage('There was a server error')
                }
                setIsLoading(false);
            });
    }, [apiSubscriptionDetails]);

    const NavBarWrapper = ({children}) => {
        return (
            <>
                {children}
                <div className='event_nav' style={{zIndex: 0}}>
                    <Link to={`/groups/${id}`}>
                        <div className="fa-button">
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                size={"xl"}
                            />
                        </div>
                    </Link>
                </div>
            </>
        )
    }

    if (isLoading) {
        return (
            <NavBarWrapper>
                <Loading />
            </NavBarWrapper>
        )    
    }

    if (!isLoading && errorMessage) {
        return (
            <NavBarWrapper>
                <ErrorMessage errorMessage={errorMessage} />
            </NavBarWrapper>
        )    
    }

    return (
        <div>
            <div className="org-page_details">
                <div className='c2a'>
                    <h6>
                        Connect with others, share events, and show off your vibe!
                    </h6>
                </div>
                <div>
                    <div id="subscribe_card" className='card'>
                        <div className='forward'>
                            <h5>
                                Billed Weekly based on Usage
                            </h5>
                            <h6>
                                <b>Maximize your vibe: </b> $0.50 for the first 5 events per period
                            </h6>
                            <h6>
                                <b>After that, </b> each extra event costs only $0.10
                            </h6>
                            <h5>
                                Create a Revibe event to increase usage
                            </h5>
                            <h6>
                                <b>Max out </b> with 100 events per week
                            </h6>
                            <h6>
                                <b>Zero fees </b> for weeks with 0 events
                            </h6>
                            {subscriptionDetails && (subscriptionDetails?.canceled_at !== null) &&
                                <ErrorMessage errorMessage={"By resubscribing now, you create a new plan with a new billing day, and your event cost meter will be reset."} />
                            }
                            <b>
                                <ProductDisplay subscriptionDetails={subscriptionDetails}/>                
                            </b>
                        </div>
                        <div className='event_org_info_wrapper'>
                            <h4>
                                No hidden fees. It's that simple.
                            </h4>
                            <p>
                                Creating between 1 and 5 events costs $0.50 total
                            </p>
                            <div id='price_item_list'>
                                
                                <div className='event_org_info_item'>
                                    <p className='event_org_info_item_wrapper'>
                                        <span className='event_org_info_label'>1 used</span>
                                    </p>
                                    <p className='event_org_info_value'>
                                        <b>$0.50</b>
                                    </p>
                                </div>  
                                <div className='event_org_info_item'>
                                    <p className='event_org_info_item_wrapper'>
                                        <span className='event_org_info_label'>2 used</span>
                                    </p>
                                    <p className='event_org_info_value'>
                                        <b>$0.50</b> <br />($0.25/event)
                                    </p>
                                </div>  
                                <div className='event_org_info_item'>
                                    <p className='event_org_info_item_wrapper'>
                                        <span className='event_org_info_label'>3 used</span>
                                    </p>
                                    <p className='event_org_info_value'>
                                        <b>$0.50</b> <br />(~$0.17/event)
                                    </p>
                                </div>  
                                <div className='event_org_info_item'>
                                    <p className='event_org_info_item_wrapper'>
                                        <span className='event_org_info_label'>4 used</span>
                                    </p>
                                    <p className='event_org_info_value'>
                                        <b>$0.50</b> <br />(~$0.13/event)
                                    </p>
                                </div>  
                                <div className='event_org_info_item'>
                                    <p className='event_org_info_item_wrapper'>
                                        <span className='event_org_info_label'>5+ used</span>
                                    </p>
                                    <p className='event_org_info_value'>
                                        <b>$0.10 per event</b> <br /><i>(Best value)</i>
                                    </p>
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div className='event_nav' style={{zIndex: 0}}>
                <Link to={`/groups/${id}`}>
                    <div className="fa-button">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size={"xl"}
                        />
                    </div>
                </Link>
                <div>
                    
                <b>
                    <ProductDisplay subscriptionDetails={subscriptionDetails}/>
                </b>
                
                </div>
            </div>
        </div>
    );
};

export default OrgSubscribe;
