/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'

import SearchNav from '../SearchNav';
import Loading from '../../components/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';

import './EventList.css';

import config from '../../config';

import EventCalendar from '../../components/EventCalendar'
import EventTable from '../../components/EventTable'

import dayjs from 'dayjs';

const EventList = ({ id }) => {
    const currentDate = dayjs();
    const startWeek = currentDate.startOf('week');
    const endWeek = currentDate.endOf('week');

    const [showWeek, setShowWeek] = useState(true);
  

    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1); // IDEA: Allow for db page changes in the future (not to be confused with the page subset on the UI)
    const [paginationData, setPaginationData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    
    // State to track if it's the first render
    const [isFirstRender, setIsFirstRender] = useState(true);

    const [eventServiceDown, setEventServiceDown] = useState(false);

    const api = config.createApiUrl('events', `/event/org/${id}`, config.URL_TYPES.API);
    
    // Search Params
    const [startDate, setStartDate] = useState(startWeek); // Current date as default
    const [endDate, setEndDate] = useState(endWeek); // Adjust as needed
    const [searchString, setSearchString] = useState(''); // Default search string

    // Example function to handle start date change
    const handleStartDateChange = (newValue) => {
      setStartDate(newValue); // Directly use the dayjs object
      setIsLoading(true);
    };

    const handleEndDateChange = (newValue) => {
      setEndDate(newValue); // Directly use the dayjs object
      setIsLoading(true);
    };

    // 
    // Calandar Helper
    // 

    const loadEventsForDateRange = (calendarDateInfo) => {
      if(calendarDateInfo) {
        const params = new URLSearchParams({
          page: page,
          search: showWeek ? '' : searchString,
          start_date: new Date(calendarDateInfo.startStr).getTime(),
          end_date: new Date(calendarDateInfo.endStr).getTime()
        }).toString();

        axios.get(`${api}?${params}`)
        .then(response => {
          // handle success
          setEvents(response.data.events);
          setPaginationData(response.data.pagination);
        })
        .catch(err => {
          // handle error
          if (!err.response?.data) {
            setEventServiceDown(true)
          }
          console.error(err);
        })
      }

      if (!calendarDateInfo && startDate && endDate) {
        const params = new URLSearchParams({
          page: page,
          search: showWeek ? '' : searchString,
          start_date: new Date(startDate).getTime(),
          end_date: new Date(endDate).getTime()
        }).toString();

        axios.get(`${api}?${params}`)
        .then(response => {
          // handle success
          setEvents(response.data.events);
          setPaginationData(response.data.pagination);
        })
        .catch(err => {
          // handle error
          if (!err.response?.data) {
            setEventServiceDown(true)
          }
          console.error(err);
        })
      }

      setIsLoading(false);
    };
  
    useEffect(() => {
      let debounceTimer;
  
      if (isFirstRender) {
        // Bypass the delay on the first render
        setIsLoading(true);
        loadEventsForDateRange();
        setIsFirstRender(false); // Mark that the first render has occurred
      } else {
        // Apply the delay on subsequent renders
        debounceTimer = setTimeout(() => {
          setIsLoading(true);
          loadEventsForDateRange();
        }, 2000); // 2000ms delay
      }
  
      // Cleanup function to clear the timer
      return () => debounceTimer && clearTimeout(debounceTimer);
    }, [page, startDate, endDate, searchString]); // Dependencies

    const handleSearch = event => {
      setPage(1);
      setSearchString(event.target.value);
    };

    const toggleView = (isCalendar) => {
      if(isCalendar){
        setShowWeek(true);
      } else {
        setShowWeek(false);
        handleStartDateChange(dayjs(startWeek))
        handleEndDateChange(dayjs(endWeek))
      }
    }

    if (isLoading) {
      // IDEA: Handle the screen jumping a better
      return (
        <div style={{
            height: "24rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Loading />
        </div>
      )
    }

    return (
      <div>
        <div className='event-switch-container'>
          <div className='event-switch'>
            <div className={showWeek ? 'button' : 'button inactive'}
                onClick={() => toggleView(true)}>
              <FontAwesomeIcon icon={faCalendarWeek} />
              Calendar
            </div>
            <div className={showWeek ? 'button inactive' : 'button'}
                onClick={() => toggleView(false)}>
              <FontAwesomeIcon icon={faTableList} />
              Table
            </div>
          </div>
        </div>
        {showWeek && events &&
          <EventCalendar
            events={events}
            onDateChange={loadEventsForDateRange}
            isFirstRender={isFirstRender}
            eventServiceDown={eventServiceDown}
          />
        }
        {!showWeek && events &&
          <>
            <div className="input-container">
              <label>
                Search
              </label>
              <input style={{padding: "0.6rem"}} type="text" value={searchString} onChange={handleSearch} placeholder="Search events" />
            </div>

            <EventTable 
              events={events}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              eventServiceDown={eventServiceDown}
            />
          </>
        }
      </div>
    );
};

export default EventList;