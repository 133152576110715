/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

import './GroupNotFound.css'

import EventNav from '../EventNav';

import config from '../../config';

const revibeHomeURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE)

const GroupNotFound = () => {
  const [beatStates, setBeatStates] = useState({
      faCalendarDays: false,
      faMagnifyingGlass: false,
      faGear: false,
  });

  const animateIcon = (iconName) => {
    setBeatStates((prevBeatStates) => ({
      ...prevBeatStates,
      [iconName]: true,
    }));
  
    setTimeout(() => {
      setBeatStates((prevBeatStates) => ({
        ...prevBeatStates,
        [iconName]: false,
      }));
    }, 800); // 500ms for the animation duration
  };

  return (
    <>
      <div className="not-found card">
        <h1 className="org-title">Hmm...</h1>
        <h4>We couldn't find the group, sorry!</h4>
        <h6>The owner of this group may have hidden their page.</h6>
        <h6>Refresh your world with more exciting events.</h6>
        <Link to={revibeHomeURL} className="full_width button">
          See more Revibe events
        </Link>
      </div>
      <EventNav animateIcon={animateIcon} beatStates={beatStates} />
    </>
  );
}

export default GroupNotFound;
