/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState, useEffect } from 'react';
import logoBlock from '../../logo-emblem.png'; // Adjust this path to your actual image path
import logoBlockDark from '../../logo-emblem-metal.png';

import LoginForm from '../../components/LoginForm/LoginForm';
import UserSignupForm from '../../components/UserSignupForm/UserSignupForm';
import EventListPreview from '../../components/EventListPreview/EventListPreview';

import './GetStarted.css'
import { useNavigate } from 'react-router-dom';

import config from '../../config';

const url = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

const GetStarted = ({livesInModal, setModalIsOpen}) => {
  const [isNewUser, setNewUser] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener('change', handleChange);

    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  const toggleUser = () => {
    setNewUser(!isNewUser);
  };

  const loadForgotPage = () => {
    window.location.href = `${url}forgot-password`;
  }
  
  return (
    <div className='getting-started-page'>
      <div className="logo_wrapper">
        <img className="logo-block" src={isDarkMode ? logoBlockDark : logoBlock} alt="Revibe Logo" />
      </div>

      <EventListPreview />
      
      {/* Close modal if the entire authentication flow is in a modal */}
      { 
        isNewUser ? 
        <UserSignupForm
          isNewUser={isNewUser} 
          toggleUser={toggleUser} 
          livesInModal={livesInModal}
          setModalIsOpen={setModalIsOpen}
        />  : 
        <LoginForm
          isNewUser={isNewUser} 
          toggleUser={toggleUser} 
          livesInModal={livesInModal}
          setModalIsOpen={setModalIsOpen}
        />
      }
      
      <h4 className="c2a form_toggle" onClick={() => loadForgotPage()}>
          Forgot Password?
      </h4>
    </div>
  );
};

export default GetStarted;
