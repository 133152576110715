/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';

import StockImage from '../StockImage'

import './PictureGallery.css'


const PictureGallery = ({ onSelectImage, selectedImage, images }) => {
  return (
    <div className="picture-gallery">
      {images.map((image, index) => (
        <StockImage
          key={index}
          onSelectImage={onSelectImage}
          selectedImage={selectedImage}
          profilePicture={image} />
        )
      )}
    </div>
  );
};

export default PictureGallery;
