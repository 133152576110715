/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import ErrorMessage from '../../components/ErrorMessage';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';

import config from '../../config';

const api = config.createApiUrl('directory', `/org/`, config.URL_TYPES.API);
const url = config.createApiUrl('directory', `/groups`, config.URL_TYPES.BASE);

const OrgSignupForm = () => {
    
    const [orgName, setOrgName] = useState('');
    const [overview, setOverview] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(api, {
                name: orgName,
                overview,
                description,
                email,
            });

            // If organization creation is successful
            if (response.status === 201) {
                console.log('Organization created successfully: ', response.data.organization);
                window.location.href = `${url}/${response.data.organization.id}`;    
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error(error);
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setErrorMessage(error.response.data.message);  // Display the error message from server
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage(error.message);
            }
        }
    };

    return (
        <div className='form-container'>
            <form>
                <div className='card'>
                    <h3 className='org-info-heading'>Basic Info</h3>
                    <div className="input-group">
                        <div className='input-container'>
                            <label>
                                Group Name
                            </label>
                            <input type="text" value={orgName} onChange={(e) => setOrgName(e.target.value)} required />
                        </div>
                    </div>
                    <div className="input-group">
                        <div className='input-container'>
                            <label>
                                Email
                            </label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <h3 className='org-info-heading'>More Info</h3>
                    <div className="input-group">
                        <div className='input-container'>
                            <label>
                                Overview
                            </label>
                            <input type="text" value={overview} onChange={(e) => setOverview(e.target.value)} />
                        </div>
                    </div>
                    <div className="input-group">
                        <div className='input-container'>
                            <label>
                                Description
                            </label>
                            <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </form>
            <div className='org-nav'>
                <Link to={`/groups/`}>
                    <div className="fa-button">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            size={"xl"}
                        />
                    </div>
                </Link>
                <div onClick={handleSubmit}>
                    <div className="fa-button" style={{cursor: 'pointer'}}>
                        <FontAwesomeIcon
                            icon={faSave}
                            size={"xl"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default OrgSignupForm;
