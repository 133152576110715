/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Loading from '../../components/Loading';
import OrgHighlight from '../../components/OrgHighlight';

import config from '../../config';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUserCircle, faCircleXmark, faCircleInfo, faCircleQuestion, faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';

import './OrgList.css';
import ErrorMessage from '../../components/ErrorMessage';

const api = config.createApiUrl('directory', `/org/`, config.URL_TYPES.API);
const identityURL = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);
const eventStatsURL = config.createApiUrl('events', `/event_stats/org`, config.URL_TYPES.API);

const OrgList = () => {
    const [orgs, setOrgs] = useState([]);
    const [eventStats, setEventStats] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fullDetails, setFullDetails] = useState(false);
    const [needAuth, setNeedAuth] = useState(false);

    const [primaryOrg, setPrimaryOrg] = useState(null);

    const optionsDate = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    // IDEA: Refactor to a utility file for all dates in app, assume milliseconds
    const formatDisplayDateFromMilliseconds = (inputDate) => {
        const fDate = new Date(inputDate).toLocaleDateString('en-US', optionsDate);
        
        // Remove leading zero from hours if present
        return fDate.replace(/^0(?:0:)?/, '');
    }
    
    const fetchEventStatsForOrg = async (org_ids) => {
        const startDate = new Date().getTime() - 604800000;
        const endDate = new Date().getTime();
  
        if (org_ids.length < 1) {
          let data = []; // The reduce function will calculate 0 events 
          data['startDate'] = startDate;
          data['endDate'] = endDate;
          setEventStats(data);
          return
        }
        try {
            const params = {
                start_date: startDate,
                end_date: endDate,
                org_id: org_ids
            };
            const res = await axios.get(eventStatsURL, { params });
            // Attach date range to stats response
            res.data['startDate'] = startDate;
            res.data['endDate'] = endDate;
            setEventStats(res.data);
        } catch (err) {
            if (err.response?.status === 401) {
                setNeedAuth(true);
            } else {
                console.error(err);
                setErrorMessage('There was a server error.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchUserOrgs = async () => {
            try {
                const res = await axios.get(api); 
                setOrgs(res.data.items);
                fetchEventStatsForOrg(res.data.recent_org_ids)

                // Also fetch primary 
                const primaryOrgApi = config.createApiUrl('directory', `/org/primary/`, config.URL_TYPES.API);
        
                try {
                  axios.get(`${primaryOrgApi}`)
                    .then(response => {
                    setPrimaryOrg(response.data);
                    setIsLoading(false);
                  })
                  .catch(err => {
                    console.error(err)
                    if (err.response?.status == 404) {
                      setPrimaryOrg(null);
                    } else {
                      setErrorMessage('There was an error loading your primary group. Try again in a few minutes.');
                    }
                    setIsLoading(false);
                  });
                } catch {
                  setErrorMessage('There was an error loading your primary group. Try again in a few minutes.');
                  setIsLoading(false);
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    setNeedAuth(true)
                } else {
                    console.error(err);
                    setErrorMessage('There was a server error.')
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserOrgs();
    }, []);

    const OrgListNavWrapper = ({children}) => {
        return (
            <>
                <div className="org-list">
                    {children}
                </div>
                <div className='org-nav'>
                    <Link to={`${identityURL}user-profile`}>
                        <div className="fa-button">
                            <FontAwesomeIcon
                                icon={faUserCircle}
                                size={"xl"}
                            />
                        </div>
                    </Link>
                    <Link to={`new`}>
                        <div className="fa-button">
                            <FontAwesomeIcon
                                icon={faSquarePlus}
                                size={"xl"}
                            />
                        </div>
                    </Link>
                </div>
            </>
        )
    }

    const makePrimaryOrg = async (org) => {
        const api = config.createApiUrl('directory', `/org/primary/${org.organization_id}`, config.URL_TYPES.API);
    
        try {
            axios.put(`${api}`)
            .then(response => {
                setPrimaryOrg(org)
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
                }
                setIsLoading(false);
            });
        } catch {
            setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
            setIsLoading(false);
        }
    };

    if (isLoading || needAuth) {
        return (
            <OrgListNavWrapper>
                <Loading />
            </OrgListNavWrapper>
        )
    }

    if (errorMessage) {
        return (
            <OrgListNavWrapper>
                <ErrorMessage errorMessage={errorMessage} />
            </OrgListNavWrapper>
        )
    }

    // Reduce function to accumulate total events
    let totalEvents = eventStats.reduce((total, org) => {
        if ( org.event_count ) {
            return total + org.event_count;
        }
        return total;
    }, 0);

    const getEventStatsByOrganizationId = (data, orgId) => {
        return data.find(item => item.organization_id === orgId) || null;
    }

    const getEventInfo = (data, orgId) => {
        let orgStats = getEventStatsByOrganizationId(data, orgId);
        if (orgStats) {
            orgStats['startDate'] = data['startDate'];
            orgStats['endDate'] = data['endDate'];
        }
        return orgStats;
    }

    return (
        <OrgListNavWrapper>
            {primaryOrg &&
                <>
                    <h1 className="org-title">Primary Group</h1>
                    <OrgHighlight 
                        key={primaryOrg.organization_id} 
                        org={primaryOrg}
                        eventInfo={getEventInfo(eventStats, primaryOrg.organization_id)}
                    >
                        <div className='primary_star_button button'>
                            <FontAwesomeIcon icon={faStar} />
                        </div>
                        <Link to={`/groups/${primaryOrg.organization_id}`}>
                            <div className="button">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    size={"lg"}
                                    />
                                Details
                            </div>
                        </Link>
                    </OrgHighlight>
                </>
            }
            {orgs.length !== 0 &&
                <>
                    <h1 className="org-title">Manage Groups</h1>
                    <div className="org-list_subscription_recent_costs-full_details_button"
                            onClick={() => setFullDetails(!fullDetails)}>
                        <FontAwesomeIcon
                            icon={!fullDetails ? faCircleQuestion : faCircleXmark}
                            size={"lg"}
                        />
                        {!fullDetails ? `Learn More` : `Hide Details`}
                    </div>
                    <div className="org-list_subscription_recent_costs">
                        <h3 className="org-title org-list_subscription_recent_costs-amount">
                            {totalEvents}
                        </h3>
                        <h4 className="org-title">
                        Events Created
                        </h4>
                        <h6 className='org-title'>
                            Across subscriptions <br />
                            during the past week starting on
                        </h6>
                        <h5 className='org-title'>
                            {formatDisplayDateFromMilliseconds(eventStats['startDate'])}
                        </h5>
                        {fullDetails &&
                        <>
                            <div className="org-list_subscription_recent_costs-details">
                                <p className='c2a c2a-brighter'>
                                    <b>
                                        Any subscriptions are billed individually - each event subscription has its own weekly cycle
                                    </b>
                                </p>
                                <p>The displayed total includes all created events for all groups</p>
                            </div>
                            <div className="org-list_subscription_recent_costs-full_details_button"
                                onClick={() => setFullDetails(!fullDetails)}
                                style={{margin: 0}}>
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    size={"lg"}
                                />
                                Hide Details
                            </div>
                        </>
                        }
                    </div>
                </>
            }
            
            <div className='org-list'>
                {orgs.map((org, index) => (
                    <OrgHighlight 
                        key={org.organization_id} 
                        org={org}
                        eventInfo={getEventInfo(eventStats, org.organization_id)}
                    >
                        {org.subscription?.canceled_at === null &&
                            <div onClick={() => makePrimaryOrg(org)} className='primary_star_button button'>
                                <FontAwesomeIcon icon={org?.organization_id === primaryOrg?.organization_id ? faStar: faOutlineStar} />
                            </div>
                        }
                        <Link to={`/groups/${org.organization_id}`}>
                            <div className="button">
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    size={"lg"}
                                />
                                Details
                            </div>
                        </Link>
                    </OrgHighlight>
                ))}
                {orgs.length === 0 &&
                    <>
                        <h1 className="org-title">Create a Group</h1>
                        <div className='c2a'>
                            <h6>
                                Connect with others by creating a group and showcasing what you do!
                            </h6>
                            <b>
                                <Link to={`/groups/new/`}>
                                    <div className="button gradient_button">
                                        Create your own
                                    </div>
                                </Link>
                                <br />
                                No hidden fees. It's that simple.
                            </b>
                        </div>
                    </>
                }
            </div>
        </OrgListNavWrapper>
    );
};

export default OrgList;
