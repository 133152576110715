/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React from 'react';

import { Link, useLocation } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faCalendarDays, faMagnifyingGlass, faCircleUser } from '@fortawesome/free-solid-svg-icons';

import './EventNav.css';

import config from '../../config';

const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);
const forumURL = config.createApiUrl('forum', `/`, config.URL_TYPES.BASE);
const identityURL = config.createApiUrl('identity', `/`, config.URL_TYPES.BASE);

const EventNav = ({ animateIcon, beatStates }) => {
    const location = useLocation();

    const getButtonClass = (targetPath) => {
        return `fa-button event_button${location.pathname === targetPath ? '_current' : ''}`;
      };

    return (
      <div className='event_nav'>
        <Link
            to={`${eventURL}`}
            className={getButtonClass('/')}
            onClick={() => animateIcon('faCalendarDays')}
            onMouseEnter={() => animateIcon('faCalendarDays')}
        >
            <FontAwesomeIcon
                icon={faCalendarDays}
                beat={beatStates.faCalendarDays}
                size="xl" />
        </Link>
        <Link
            className={getButtonClass('/search')}
            to={`${forumURL}`}
            >
            <FontAwesomeIcon
                icon={faComments}
                size="xl" />
        </Link>
        <Link
            to={`${eventURL}search`}
            className={getButtonClass('/search')}
            onClick={() => animateIcon('faMagnifyingGlass')}
            onMouseEnter={() => animateIcon('faMagnifyingGlass')}
        >
            <FontAwesomeIcon
                icon={faMagnifyingGlass}
                beat={beatStates.faMagnifyingGlass}
                size="xl" />
        </Link>
        <Link 
            to={`${identityURL}user-profile`}
            className={getButtonClass('/settings')}
            onClick={() => animateIcon('faGear')}
            onMouseEnter={() => animateIcon('faGear')}
        >
            <FontAwesomeIcon
                icon={faCircleUser}
                beat={beatStates.faGear}
                size="xl" />
        </Link>
      </div>
    );
  };
  
  export default EventNav;
  