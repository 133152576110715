/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import axios from 'axios';

import EventNav from '../../components/EventNav';
import Loading from '../../components/Loading';
import OrgOverview from '../../components/OrgOverview';
import OrgEventSummary from '../../components/OrgEventSummary';
import GroupNotFound from '../../components/GroupNotFound';
import config from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faFilePen } from '@fortawesome/free-solid-svg-icons';

const OrgPage = () => {
    const location = useLocation();

    const [org, setOrg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let { id } = useParams();  // Getting the organization id from the route

    const api = config.createApiUrl('directory', `/org/public/${id}/public-details`, config.URL_TYPES.API)

    const [beatStates, setBeatStates] = useState({
        faCalendarDays: false,
        faMagnifyingGlass: false,
        faGear: false,
    });

    useEffect(() => {
        const fetchOrgDetails = async () => {
            try {
                const res = await axios.get(api); 
                setOrg(res.data);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };

        fetchOrgDetails();
    }, [api]);

    const animateIcon = (iconName) => {
        setBeatStates((prevBeatStates) => ({
          ...prevBeatStates,
          [iconName]: true,
        }));
      
        setTimeout(() => {
          setBeatStates((prevBeatStates) => ({
            ...prevBeatStates,
            [iconName]: false,
          }));
        }, 800); // 500ms for the animation duration
    };

    if (isLoading) {
        return <Loading />
    }

    if (!org) {
        return <GroupNotFound />
    }

    function formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    function formatWebsiteURL(url) {
        if (url.startsWith('http://') || url.startsWith('https://')) {
          return url;
        } else {
          return 'http://' + url;
        }
    }
    
    function renderAddress(address) {
        if (!address.street && !address.street2 && !address.city && !address.state && !address.postal_code && !address.country) {
            return null;
        }
    
        return (
            <>
                <h5 className='org-list-label'>Address</h5>
                <div className='org-page_details'>
                    <address>
                        {address.street && <p>{address.street} {address.street2}</p>}
                        {(address.city || address.state || address.postal_code) && <p>{address.city}{address.city && ','} {address.state} {address.postal_code}</p>}
                        {address.country && <p>{address.country}</p>}
                    </address>
                </div>
            </>
        );
    }

    function renderSocialMedia(handles) {
        const socialMediaLinks = {
          facebook: { icon: faFacebook, url: handles.facebook && `http://facebook.com/${handles.facebook}` },
          twitter: { icon: faXTwitter, url: handles.twitter && `http://twitter.com/${handles.twitter}` },
          instagram: { icon: faInstagram, url: handles.instagram && `http://instagram.com/${handles.instagram}` },
          linkedin: { icon: faLinkedin, url: handles.linkedin && `http://linkedin.com/company/${handles.linkedin}` },
        };
      
        // Create an array of valid social media links
        const validLinks = Object.keys(socialMediaLinks).filter((key) => socialMediaLinks[key].url);
      
        if (validLinks.length === 0) {
          return null; // Return null if no valid links
        }
      
        return (
          <>
            <h5 className='org-list-label'>Social Media</h5>
            <h6>Visit an external link for this group</h6>
            <div className="org-page_details">
                {validLinks.map((key) => {
                    const { icon, url } = socialMediaLinks[key];
                    return (
                        <h6 className="org-list-label social_link" key={key}>
                            <a target="_blank" rel="noopener noreferrer" href={url}>
                                <FontAwesomeIcon icon={icon} /> {key.charAt(0).toUpperCase() + key.slice(1)}
                            </a>
                        </h6>
                    );
                })}
            </div>
          </>
        );
    }

    const hideNonOrgItems = location.pathname === `/public/groups/${org.id}`;
      
    return (
        <div>
            {!hideNonOrgItems && (
                <div className='org-nav'>
                    <Link to={`/groups/${id}`}>
                        <div className="fa-button">
                            <FontAwesomeIcon
                                icon={faArrowLeft}
                                size={"xl"}
                            />
                        </div>
                    </Link>
                    <Link to={`/groups/${org.id}/edit`}>
                        <div className="fa-button">
                            <FontAwesomeIcon
                                icon={faFilePen}
                                size={"xl"}
                            />
                        </div>
                    </Link>
                </div>
            )}
            
            <div className="org-page_details">
                
                <OrgOverview org={org} />

                <OrgEventSummary org={org} />

                <div className='card'>
                    {org.media_handles && renderSocialMedia(org.media_handles)}

                    {(org.contact_phone || org.contact_email || org.website_url) &&
                        <h5 className='org-list-label'>Contact Details</h5>
                    } 
                    {org.contact_phone &&
                        <div className='event_org_info_item'>
                            <p className='event_org_info_item_wrapper'>
                                <span className='event_org_info_label'>Phone</span>
                            </p>
                            <p className='event_org_info_value'>
                                {formatPhoneNumber(org.contact_phone)}
                            </p>
                        </div>
                    }
                    {org.contact_email &&
                        <div className='event_org_info_item'>
                            <p className='event_org_info_item_wrapper'>
                                <span className='event_org_info_label'>Email</span>
                            </p>
                            <p className='event_org_info_value'
                               style={{wordBreak: "break-word"}}>
                                <a href={`mailto:${org.contact_email}`}>{org.contact_email}</a>
                            </p>
                        </div>
                    }
                    {org.website_url &&
                        <div className='event_org_info_item'>
                            <p className='event_org_info_item_wrapper'>
                                <span className='event_org_info_label'>Website</span>
                            </p>
                            <p className='event_org_info_value'
                               style={{wordBreak: "break-word"}}>
                                <a href={formatWebsiteURL(org.website_url)}>{org.website_url}</a>
                            </p>
                        </div>
                    }

                    <br/>
                    
                    {org.address && renderAddress(org.address)}
                </div>

                {hideNonOrgItems && (
                    <div className='c2a'>
                        <h6>
                            Connect with others by creating a group and showcasing what you do!
                        </h6>
                        <b>
                            <Link to={`/groups/`}>
                                <div className="button gradient_button">
                                    Create your own
                                </div>
                            </Link>
                            <br />
                            No hidden fees. It's that simple.
                        </b>
                    </div>
                )}
            </div>
            <EventNav animateIcon={animateIcon} beatStates={beatStates} />
        </div>
    );
};

export default OrgPage;
