/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../components/Loading';
import C2AMessage from '../../components/C2AMessage';
import OrgHighlight from '../../components/OrgHighlight';
import OrgEventSummary from '../../components/OrgEventSummary/OrgEventSummary';
import GroupNotFound from '../../components/GroupNotFound';
import ErrorMessage from '../../components/ErrorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCalendarPlus, faFilePen, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';
import config from '../../config';

const OrgDetails = () => {
    const [errorMessage, setErrorMessage] = useState(null);

    const [org, setOrg] = useState(null);
    const [eventsUsed, setEventsUsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isNewSubscription, setIsNewSubscription] = useState(false);
    const [eventDataLoadFailed, setEventDataLoadFailed] = useState(false);

    let { id } = useParams();
    let location = useLocation();
    const eventURL = config.createApiUrl('events', `/`, config.URL_TYPES.BASE);
    const api = config.createApiUrl('directory', `/org/${id}/full-details`, config.URL_TYPES.API);
    
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const newSubscription = queryParams.get('new_subscription');

        const delay = newSubscription ? 10000 : 0; // Delay of 5 seconds if new_subscription is true

        // IDEA: Refactor this logic to reuse the code below
        // but, this creates a new row in the database for
        // setting the primary org for a user after they subscribe
        // it also overwrites the primary org if they already had one 
        if (newSubscription) {
            const api = config.createApiUrl('directory', `/org/primary/${id}`, config.URL_TYPES.API);
        
            try {
                axios.post(`${api}`)
                .then(response => {
                    setOrg(prevOrg => ({
                        ...prevOrg,
                        is_primary_org: true
                    }));
                })
                .catch(err => {
                    console.error(err)
                    if (err.response) {
                        setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
                    }
                });
            } catch {
                setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
            }
        }

        setIsNewSubscription(newSubscription)
        setIsLoading(true);

        const fetchOrgDetails = async () => {
            try {
                const orgRes = await axios.get(api);
                let fullOrg = orgRes.data;
                setOrg(fullOrg);

                const startDate = new Date().getTime() - 604800000;
                const endDate = new Date().getTime();
                if (startDate && endDate) {
                    const eventsUsedAPI = config.createApiUrl(
                        'events',
                        `/event_stats/org?start_date=${startDate}&end_date=${endDate}&org_id[]=${id}`,
                        config.URL_TYPES.API
                    );
                    const eventsUsedRes = await axios.get(eventsUsedAPI);
                    let eventInfo = eventsUsedRes.data[0];
                    if (eventInfo) {
                        eventInfo['startDate'] = startDate;
                        eventInfo['endDate'] = endDate;
                        fullOrg['subscription']['eventInfo'] = eventInfo;
                        fullOrg['subscription']['events_used'] = eventInfo.event_count;
                    }
                    setOrg(fullOrg);
                }
            } catch (err) {
                console.error(err);
                setEventDataLoadFailed(true);
            }
            setIsLoading(false);
        };

        const timeoutId = setTimeout(fetchOrgDetails, delay);

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, [api, location.search]);

    const [resetDate, setResetDate] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [remainingEvents, setRemainingEvents] = useState(false);
    const eventsQuota = 100;

    const [showKickstartMessage, setShowKickstartMessage] = useState(false);
    const [showAlmostAtLimit, setShowAlmostAtLimit] = useState(false);
    const [showEventLimitReached, setShowEventLimitReached] = useState(false);

    const getUiState = (events_used) => {
        if (isActive) {
            if (eventsQuota === 100 && events_used === 100) return 'showEventLimitReached';
            if (eventsQuota === 100 && events_used >= 95) return 'showAlmostAtLimit';
        }
        return null;
    };

    useEffect(() => {
        if (org) {
            const date = org.subscription?.current_period_end * 1000;
            const optionsDate = {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };
            const fDate = new Date(date).toLocaleDateString('en-US', optionsDate);

            setResetDate(fDate);
            setIsActive(org.subscription?.canceled_at === null);
            setEventsUsed(org.subscription?.events_used);
            setRemainingEvents(eventsQuota - eventsUsed);

            setShowKickstartMessage(!org.subscription);

            const uiState = getUiState(org.subscription?.events_used);
            setShowEventLimitReached(uiState === 'showEventLimitReached');
            setShowAlmostAtLimit(uiState === 'showAlmostAtLimit');
        }
    }, [org]);

    const NavBarWrapper = ({ children }) => {
        return (
            <>
                {children}
                <div className='org-nav'>
                    {!isNewSubscription &&
                        <Link to={`/groups/`}>
                            <div className="fa-button">
                                <FontAwesomeIcon icon={faArrowLeft} size={"xl"} />
                            </div>
                        </Link>
                    }
                </div>
            </>
        );
    };

    const makePrimaryOrg = async () => {
        const api = config.createApiUrl('directory', `/org/primary/${org.id}`, config.URL_TYPES.API);
    
        try {
            axios.put(`${api}`)
            .then(response => {
                setOrg(prevOrg => ({
                    ...prevOrg,
                    is_primary_org: true
                }));
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err)
                if (err.response) {
                    setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
                }
                setIsLoading(false);
            });
        } catch {
            setErrorMessage('There was an error setting your primary group. Try again in a few minutes.');
            setIsLoading(false);
        }
    };

    if (isLoading && isNewSubscription) {
        return (
            <NavBarWrapper>
                <div style={{ padding: '1rem', margin: 0, textAlign: 'center' }}>
                    <h3>
                        Thank you for subscribing!
                    </h3>
                    <div style={{ padding: '3rem 0' }}>
                        <Loading />
                    </div>
                    <h5>
                        <FontAwesomeIcon icon={faCalendarPlus} /> Start a new vibe
                    </h5>
                    <h6>
                        <b>
                             Create new events <br />to share vibes with others
                        </b>
                    </h6>
                    <p style={{ paddingTop: '3rem' }}>
                        The next page will have your subscription info. If it hasn’t loaded, please refresh as we sync with Stripe.
                    </p>
                </div>
            </NavBarWrapper>
        );
    } else if (isLoading) {
        return (
            <NavBarWrapper>
                <Loading />
            </NavBarWrapper>
        );
    } else if (!org) {
        return (
            <>
                <GroupNotFound />
                <h6 style={{ padding: '1rem', margin: 0, textAlign: 'center' }}>
                    <b>
                        If you're attempting to access or modify a group and are encountering difficulties, please return to the main page and verify the details. If you continue to face challenges, we're here to assist you. Please contact <a href="mailto:support@revibe.life">support@revibe.life</a> for personalized support.
                    </b>
                </h6>
                <div className='org-nav'>
                    <Link to={`/groups/`}>
                        <div className="fa-button">
                            <FontAwesomeIcon icon={faArrowLeft} size={"xl"} />
                        </div>
                    </Link>
                </div>
            </>
        );
    } else {
        return (
            <div>
                <div className='org-events'>
                    <div className='org-list'>
                        <OrgHighlight 
                            org={org} 
                            eventInfo={org.subscription?.eventInfo}
                        >
                        {isActive &&
                            <div onClick={() => makePrimaryOrg()} className='primary_star_button button'>
                                <FontAwesomeIcon icon={org.is_primary_org ? faStar: faOutlineStar} />
                            </div>
                        }
                        </OrgHighlight>
                    </div>

                    {resetDate &&
                        isActive &&
                        <div style={{ textAlign: "center" }}>
                            <div>
                                Next billing period starts on
                            </div>
                            <b>
                                {resetDate}
                            </b>
                        </div>
                    }

                    <Link className="full_width" to={`/groups/${org.id}/subscribe`}>
                        <div className="button gradient_button">
                            Update Subscription
                        </div>
                    </Link>

                    {showKickstartMessage &&
                        <C2AMessage
                            headline="Kickstart Your Event Sharing!"
                            actionText="Subscribe"
                            actionLink={`/groups/${org.id}/subscribe`}
                            features="Subscribe to create events."
                            cost="Create 10 events. Only $1."
                        />
                    }

                    {showAlmostAtLimit &&
                        <C2AMessage
                            headline={`${remainingEvents} events left`}
                            actionText="See Events"
                            actionLink={eventURL}
                            cost="Remember, 100 is the max for a week. Set a good vibe!"
                        />
                    }

                    {showEventLimitReached &&
                        <C2AMessage
                            headline="Wow, you created 100 events in a week!"
                            actionText="See Events"
                            actionLink={eventURL}
                            cost="Wait at least a week since your oldest event to create another."
                        />
                    }

                    <hr />

                    {isActive &&
                        <OrgEventSummary org={org} />
                    }

                    {isActive &&
                        showEventLimitReached &&
                        <div>
                            Your contributions refresh our community. View Your Events for the month
                        </div>
                    }
                </div>

                <div className='org-nav'>
                    <Link to={`/groups/`}>
                        <div className="fa-button">
                            <FontAwesomeIcon icon={faArrowLeft} size={"xl"} />
                        </div>
                    </Link>
                    {resetDate &&
                        isActive &&
                        !eventDataLoadFailed &&
                        (eventsQuota !== eventsUsed) &&
                        <Link to={`/groups/${org.id}/newevent`}>
                            <div className="fa-button">
                                <FontAwesomeIcon icon={faCalendarPlus} size={"xl"} />
                            </div>
                        </Link>
                    }
                    <Link to={`/groups/${org.id}/edit`}>
                        <div className="fa-button">
                            <FontAwesomeIcon icon={faFilePen} size={"xl"} />
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
};

export default OrgDetails;
