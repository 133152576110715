/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Event from '../Event';

import ErrorMessage from '../../components/ErrorMessage';
import C2AMessage from '../../components/C2AMessage';

import { DataGrid } from '@mui/x-data-grid';

import './EventTable.css';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const columns = [
  {
    flex: 2, // Adjusted for proportional space allocation
    field: 'start_date',
    headerName: 'Date',
    type: 'date',
    sortable: true,
    filterable: false,
    valueGetter: (params) => {
      // Assuming the date is in milliseconds and needs to be formatted
      const date = new Date(params.value);
      // Format the date as needed, e.g., MM/DD/YYYY
      return date;
    },
  },
  {
    flex: 3, // Adjusted for the remainder of the space
    field: 'event_name',
    headerName: 'Event',
    description: 'The name of the event',
    sortable: true,
    filterable: false,
  },
];

const EventTable = ({ events, handleStartDateChange, handleEndDateChange, startDate, endDate, eventServiceDown }) => {
  
  const [activeEvent, setActiveEvent] = useState(null)

  const chooseTableEvent = (data) => {
      setActiveEvent(data)
  }

  // Render full title/color for activeEvent (clicked event)
  // const isActiveEvent = (data) => {
  //   if (activeEvent?.event_id == data.event._def.extendedProps.event_id) {
  //     return [data.event.title, 'active']
  //   }
  //   return [data.event.title, '']
  // }

  const CustomToolbar = (props) => {
    return (
      <div>
        {/* Other custom elements if needed */}
        <button onClick={props.onDismiss}>Cancel</button>
      </div>
    );
  };

  return (
    <div>
      <div className='event_table-date_filters'>
        <div className="mui-input-container">
          <label className='event_table-date_filters-label'>
            <span>
              From
            </span>
            <span>
              12 AM
            </span>
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              ToolbarComponent={CustomToolbar}
              value={startDate}  // Convert to Day.js object
              onChange={(newValue) => {
                if (dayjs.isDayjs(newValue)) { // Check if newValue is a Day.js object
                  // Adjust the time to the start of the day (12 AM)
                  const startOfDay = newValue.startOf('day');
                  handleStartDateChange(startOfDay);  // Now handles the start of the day
                }
              }}
            />
          </LocalizationProvider>
        </div>

        <div className="mui-input-container">
          <label className='event_table-date_filters-label'>
            <span>
              To
            </span>
            <span>
              12 AM
            </span>
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}  // Convert to Day.js object
              onChange={(newValue) => {
                if (dayjs.isDayjs(newValue)) {  // Check if newValue is a Day.js object
                  // Adjust the time to the start of the day (12 AM)
                  const startOfDay = newValue.startOf('day');
                  handleEndDateChange(startOfDay);  // Now handles the start of the day
                }
              }}
              />
          </LocalizationProvider>
        </div>
      </div>

      {events.length == 0 &&
        <>
          <div style={{borderRadius: "0.5rem", border: 0}} className='error-message'>
            {eventServiceDown ? 'Unable to load events. Please try again later' : 'Weekly Revibe: No Events'}
          </div>
        </>
      }

      {events.length !== 0 &&
        <div className='event_table_container'>
          <DataGrid
            classes={{
              root: 'mui-revibe-root',
              row: 'mui-revibe-row',
              cell: 'mui-revibe-cell',
              columnHeaders: 'mui-revibe-headers',
              columnHeader: 'mui-revibe-header',
              footerContainer: 'mui-revibe-footer',
            }}
            rows={events}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            onRowClick={(params) => {
                params.row['event_id'] = params.row.id; 
                return setActiveEvent(params.row);
              }
            }
          />
        </div>
      }

      {activeEvent &&
      <>
        <h6 className='disclaimer'>
          Disclaimer: Event visuals use <br />AI-generated stock images.
        </h6>
        <Event event_id={activeEvent.id} {...activeEvent} />
      </>
      }

      {!activeEvent && events.length > 0 &&
        <div>
          <h5 className='calendar-microcopy'>
            Select an event from the table
          </h5>
        </div>
      }
    </div>
  )
  
}

export default EventTable;
